import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Statement from "../components/statement"

const PreamblePage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>
    <h4>Preamble</h4>
    <Statement/>
  </Layout>
)

export default PreamblePage
