import React from "react"

const Statement = () => (
  <section>
    <h4>A Personal Statement</h4>
    <p>
      I'm taking a break and thinking about the next ten years. ApacheCon NA was awesome and great to see so many
      friends old and new.
    </p><p>
    In Autumn 2016, co-founded Tech Up North Ltd.
  </p><p>
    Stepping back a little, there seems quite a lot of back-story to browse below. Looking forward, I’m sure there must
    be many more twists and turns to come.
  </p><p>
    The bits I love most are tests turning green and seeing people learning to learn to become better.
  </p><p>
    The bits I hate most are tangles with red-tape and interminable waits for software to build.
  </p><p>
    Increasingly I'm into ubiquitous polyglotism - persistence, programming, testing, architecture, distribution.
  </p><p>
    Test early, test often.
  </p><p>
    Though JavaScript front ends really rock, I'm still convinced that Java is best for complex server applications.
  </p><p>
    The CAP theorem amazes me in so many ways.
  </p><p>
    Searching for a wizard with serial algorithms? Then time to click next. Concurrency is a different - and I think
    fascinating - story.
  </p><p>
    Since the turn of the Noughties, I've surfed the Agile wave and - after my injury in 2010 - perhaps came full circle
    again helping Masters computer scientists learn Agile practices and processes.
  </p><p>
    And so - the injury. Yes, a typing and writing related injury.
  </p><p>
    After topping the Advanced Computer Science Masters in the first semester and struggling through the courses in the
    second, I dropped out with little movement or use left in my right wrist. I still see my physio regularly and spend
    a couple of hours most day on exercises but I'm a commercial developer again. Phone usage is still tricky for me,
    much more so than typing.
  </p>

    <h5>Specialities</h5>
    <ul>
      <li>agile and open source methods, principles, techniques and tools</li>
      <li>early stage software projects, innovation, start-ups</li>
      <li>coaching, training and technical leadership</li>
      <li>enterprise and internet object-oriented development, design and architecture</li>
      <li>concurrent, multi-threaded and distributed JEE</li>
      <li>JavaScript (and Java) front ends</li>
    </ul>

  </section>
)

export default Statement